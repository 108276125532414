import { Navigate, createBrowserRouter } from "react-router-dom";
import Appbar from "../layout/appbar";

import LoginPage from "../pages/LoginPage";
import NewLoginPage, {
  LoginPageLayout,
} from "../pages/NewLoginPage/NewLoginPage";
import BundlesPage from "../pages/NewLoginPage/Pages/BundlesPage";
import CourseCurriculumPage from "../pages/NewLoginPage/Pages/CourseCurriculumPage/CourseCurriculumPage";

import { ListOfCoursesLayout } from "../layout/ListOfCoursesLayout";
import { QuizLayout } from "../layout/QuizLayout";
import { ResultLayout } from "../layout/ResultsLayout";
import { TestLayout } from "../layout/TestLayout";
import EBook from "../pages/EBook";
import Curriculum from "../pages/NewLoginPage/Pages/Curriculum/Curriculum";
import Search from "../pages/NewLoginPage/Pages/Search";
import ViweMore from "../pages/NewLoginPage/Pages/ViweMore";
import Quiz from "../pages/Quiz";
import MyDashboard from "../pages/SignInPages/Pages/MyDashboard/MyDashboard";
import Discussion from "../pages/SignInPages/Pages/MyDiscussionForum/Discussion";
import MyDiscussionForum from "../pages/SignInPages/Pages/MyDiscussionForum/MyDiscussionForum";
import MyDoubts from "../pages/SignInPages/Pages/MyDoubts/MyDoubts";
import MyEBooks from "../pages/SignInPages/Pages/MyEBooks/MyEBooks";
import MyExamTimetable from "../pages/SignInPages/Pages/MyExamTimetable/MyExamTimetable";
import MyLearning from "../pages/SignInPages/Pages/MyLearning/MyLearning";
import MyLiveSession from "../pages/SignInPages/Pages/MyLiveSession/MyLiveSession";
import MyNoticeboard from "../pages/SignInPages/Pages/MyNoticeboard/MyNoticeboard";
import OnlineTest from "../pages/SignInPages/Pages/MyTests/OnlineTest";
import MyTradeTimeTable from "../pages/SignInPages/Pages/MyTradeTimeTable/MyTradeTimeTable";
import UserProfile from "../pages/SignInPages/Pages/UserProfile/UserProfile";
import SigninLayout from "../pages/SignInPages/Sign-in-Layout";
import Test from "../pages/Test";
import AdmissionWithdraw from "../pages/admissionWithdraw";
import SignUp from "../pages/signUp";
import Blended from "../pages/studentCorner/blended";
import DiscussionForum from "../pages/studentCorner/discussionForum";
import ExamGuidelines from "../pages/studentCorner/examGuidelines";
import LiveSessions from "../pages/studentCorner/liveSessions";
import PracticalSlot from "../pages/studentCorner/practicalSlot";
import TradeTimeTable from "../pages/studentCorner/tradeTimeTable";

const isAuthenticated = () => {
  const accessToken = localStorage.getItem("access_token");
  return accessToken ? true : false;
};
const ProtectedRoute = ({ component, route }: any) => {
  const isAuth = isAuthenticated();
  if (isAuth && route === "/") {
    window.location.href = "/my-dashboard";
  } else {
    return component;
  }
};

export const appRouter = createBrowserRouter([
  {
    path: "/learnings/bundle/:bundleId/course/:courseId",
    element: <ListOfCoursesLayout />,
  },
  {
    path: "/registration",
    element: <SignUp />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/test/:id",
    element: <Test />,
  },
  {
    path: "/eBook/:id",
    element: <EBook />,
  },
  {
    path: "/begin-test/exam/:examId/response/:id",
    element: <TestLayout />,
  },
  {
    path: "/learnings/bundle/:bundleId/course/:courseId/quiz/:id",
    element: <Quiz />,
  },
  {
    path: "/learnings/bundle/:bundleId/course/:courseId/begin-test/quiz/:quizId/response/:id",
    element: <QuizLayout />,
  },
  {
    path: "/result/:id",
    element: <ResultLayout />,
  },
  {
    path: "/learnings/bundle/:bundleId/course/:courseId/quiz/result/:id",
    element: <ResultLayout />,
  },
  {
    path: "/my-discussion-forum/:id",
    element: <Discussion />,
  },
  {
    path: "/",
    element: <LoginPageLayout />,
    children: [
      {
        index: true,
        element: <ProtectedRoute component={<NewLoginPage />} route={"/"} />,
      },
      {
        path: "/view-more",
        element: <ViweMore />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/bundles/:bundleid",
        element: <BundlesPage />,
      },
      {
        path: "/bundles/:bundleid/course-curriculum/:courseid",
        element: <CourseCurriculumPage />,
      },
      {
        path: "/bundles/:bundleid/course-curriculum/:courseid/:topicId",
        element: <Curriculum />,
      },
    ],
  },
  //tradename
  {
    element: <Appbar />,
    children: [
      {
        index: true,
        element: <Navigate to={"/blended-lms"} />,
      },
      { path: "/blended-lms", element: <Blended /> },
      {
        path: "/trade-timetable",
        element: <TradeTimeTable />,
      },
      {
        path: "/practical-slot-booking",
        element: <PracticalSlot />,
      },
      {
        path: "/examination-guidelines",
        element: <ExamGuidelines />,
      },
      {
        path: "/student-live-sessions",
        element: <LiveSessions />,
      },
      {
        path: "/discussion-forum",
        element: <DiscussionForum />,
      },
      {
        path: "/withdraw",
        element: <AdmissionWithdraw />,
      },
    ],
  },

  {
    element: <SigninLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={"/my-dashboard"} />,
      },
      {
        path: "/my-dashboard",
        element: <MyDashboard />,
      },
      {
        path: "/my-trade-timetable",
        element: <MyTradeTimeTable />,
      },
      {
        path: "/online-test",
        element: <OnlineTest />,
      },
      {
        path: "/doubts",
        element: <MyDoubts />,
      },
      {
        path: "/e-books",
        element: <MyEBooks />,
      },
      {
        path: "/my-discussion-forum",
        element: <MyDiscussionForum />,
      },

      {
        path: "/my-learning",
        element: <MyLearning />,
      },

      {
        path: "/live-sessions",
        element: <MyLiveSession />,
      },
      {
        path: "/trade-time-table",
        element: <h1>My Trade Time Table Page</h1>,
      },
      {
        path: "/goals",
        element: <h1>My Goals Page</h1>,
      },
      {
        path: "/noticeboard",
        element: <MyNoticeboard />,
      },
      {
        path: "/exam-timetable",
        element: <MyExamTimetable />,
      },
      {
        path: "/profile",
        element: <UserProfile />,
      },
    ],
  },
]);
